import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";

import Site from "./components/x_TA_site";


function App() {
  return (
    <ChakraProvider>
      <Site />
    </ChakraProvider>
  )
}
// способ получить доступ к одному элементу в DOM, с помощью уникального id. render - чтобы отрисовать элемент React
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);