import React, { useState } from "react";
import { ReactElement } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  VStack,
  Link,
  Icon,
  Container,
  Text,
  Heading,
  Image,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  useBreakpointValue,
  Grid,
  GridItem,
  Stack,
  Divider,
  List,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { TbBrandTelegram, TbPhone, TbMapPin, TbMail, TbCircleCheck,
  TbPuzzle2, TbMessageCircleQuestion, TbBrush } from 'react-icons/tb';
import Slider from 'react-slick';
import cat2 from './pic/cat2.jpg';
import cat3 from './pic/cat3.jpg';
import cat4 from './pic/cat4.jpg';
import cat5 from './pic/cat5.jpg';
import cat7 from './pic/cat7.jpg';
import cat8 from './pic/cat8.jpg';
import dipl_1 from './pic/dipl_1.png';
import dipl_2 from './pic/dipl_2.png';
import dipl_3 from './pic/dipl_3.png';
import dipl_4 from './pic/dipl_4.png';
import sert_1 from './pic/sert_1.jpg';
import sert_2 from './pic/sert_2.jpg';
import sert_3 from './pic/sert_3.jpg';
import sert_4 from './pic/sert_4.jpg';
import logo_2 from './pic/logo_2.png';
import ta_4 from './pic/ta_4.jpg';
import cat_for_ta from './pic/cat_for_ta.gif';



const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

interface CardProps {
  heading: string;
  description: string;
  icon: ReactElement;
}

const Cards = ({ heading, description, icon }: CardProps) => {
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      overflow="hidden"
      p={5}>
      <Stack align={'center'} spacing={2}>
        <Flex
          w={32}
          h={32}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}>
          {icon}
        </Flex>
        <Box align={'center'} mt={2}>
          <Heading size="md" whiteSpace="pre-wrap">{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  )
};

interface PackageTierProps {
  title: string;
  info: string;
}
const PackageTier = ({
  title,
  info,
}: PackageTierProps) => {
  const colorTextLight = 'white';
  const bgColorLight = '#5F27FF';
  const modal1 = useDisclosure();

  return (
    <Stack
      p={3}
      py={3}
      justifyContent={{
        base: 'flex-start',
        md: 'space-between',
      }}
      direction={{
        base: 'column',
        md: 'row',
      }}
      alignItems={{ md: 'center' }}>
      <Heading size={'md'} whiteSpace="pre-wrap" w={{ base: '250px', md: '240px', lg: '300px' }}>{title}</Heading>
      <Text fontSize={'xs'} whiteSpace="pre-wrap" w={{ base: '250px', md: '110px', lg: '170px' }}>{info}</Text>
      <Stack>
        <Button
          size="md"
          color={useColorModeValue(colorTextLight)}
          bgColor={useColorModeValue(bgColorLight)}
          onClick={() => {
            modal1.onOpen();
          }}>
          Записаться
        </Button>
        <Modal isOpen={modal1.isOpen} size={'sm'} onClose={modal1.onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Запись на арт-терапию</ModalHeader>
            <ModalCloseButton />
            <ModalBody align='center' pb='20px'>
              <Flex flex={3} align={'center'} py={{ base: '3', md: '0' }}>
                <Stack spacing={4} w={'full'}>
                  <HStack pb={2}>
                    <Box color={'#5F27FF'} px={2}>
                      <Icon as={TbBrandTelegram} w={5} h={5} display={'flex'} />
                    </Box>
                    <VStack align={'start'} textAlign={'left'}>
                      <Text fontWeight={600} fontSize='sm'>Бот для предварительной записи:</Text>
                      <Text fontSize='lg'><a href="https://t.me/Record_art_bot">@record_art_bot</a></Text>
                    </VStack>
                  </HStack>
                  <HStack pb={2}>
                    <Box color={'#5F27FF'} px={2}>
                      <Icon as={TbPhone} w={5} h={5} display={'flex'} />
                    </Box>
                    <VStack align={'start'} textAlign={'left'}>
                      <Text fontWeight={600} fontSize='sm'>Телефон:</Text>
                      <Text fontSize='lg'>8-902-423-92-31</Text>
                    </VStack>
                  </HStack>
                </Stack>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Stack>
    </Stack>
  );
};

const Site = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modal1 = useDisclosure();

  const [slider, setSlider] = useState("");

  const top = useBreakpointValue({ base: '50%', md: '50%' })
  const side = useBreakpointValue({ base: '-20px', md: '-20px' })

  const cards = [
    dipl_2,
    dipl_3,
    dipl_4,
    sert_1,
    sert_2,
    sert_3,
    sert_4,
    dipl_1,
  ]

  return (
    <Container maxW={'5xl'}>
      <Box px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Avatar size={'md'} src={logo_2} />
          <HStack spacing={8} alignItems={'center'}>
            <HStack
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
                <Link href="#art">
                  <Button
                    rounded={'full'}
                    size={'sm'}
                    colorScheme='whiteAlpha'
                    color='black'
                    _hover={{
                      bg: '#5F27FF',
                      color: 'white',
                    }}>
                    Арт-терапия
                  </Button>
                </Link>
                <Link href="#about">
                  <Button
                    rounded={'full'}
                    size={'sm'}
                    colorScheme='whiteAlpha'
                    color='black'
                    _hover={{
                      bg: '#5F27FF',
                      color: 'white',
                    }}>
                    Обо мне
                  </Button>
                </Link>
                <Link href="#services">
                  <Button
                    rounded={'full'}
                    size={'sm'}
                    colorScheme='whiteAlpha'
                    color='black'
                    _hover={{
                      bg: '#5F27FF',
                      color: 'white',
                    }}>
                    Услуги
                  </Button>
                </Link>
                <Link href="#prices">
                  <Button
                    rounded={'full'}
                    size={'sm'}
                    colorScheme='whiteAlpha'
                    color='black'
                    _hover={{
                      bg: '#5F27FF',
                      color: 'white',
                    }}>
                    Цены
                  </Button>
                </Link>
                <Link href="#contacts">
                  <Button
                    rounded={'full'}
                    size={'sm'}
                    colorScheme='whiteAlpha'
                    color='black'
                    _hover={{
                      bg: '#5F27FF',
                      color: 'white',
                    }}>
                    Контакты
                  </Button>
                </Link>
            </HStack>
          </HStack>

          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack spacing={4}>
              <Link href="#art">
                <Button
                  rounded={'full'}
                  size={'sm'}
                  colorScheme='whiteAlpha'
                  color='black'
                  _hover={{
                    bg: '#5F27FF',
                    color: 'white',
                  }}>
                  Арт-терапия
                </Button>
              </Link>
              <Link href="#about">
                <Button
                  rounded={'full'}
                  size={'sm'}
                  colorScheme='whiteAlpha'
                  color='black'
                  _hover={{
                    bg: '#5F27FF',
                    color: 'white',
                  }}>
                  Обо мне
                </Button>
              </Link>
              <Link href="#services">
                <Button
                  rounded={'full'}
                  size={'sm'}
                  colorScheme='whiteAlpha'
                  color='black'
                  _hover={{
                    bg: '#5F27FF',
                    color: 'white',
                  }}>
                  Услуги
                </Button>
              </Link>
              <Link href="#prices">
                <Button
                  rounded={'full'}
                  size={'sm'}
                  colorScheme='whiteAlpha'
                  color='black'
                  _hover={{
                    bg: '#5F27FF',
                    color: 'white',
                  }}>
                  Цены
                </Button>
              </Link>
              <Link href="#contacts">
                <Button
                  rounded={'full'}
                  size={'sm'}
                  colorScheme='whiteAlpha'
                  color='black'
                  _hover={{
                    bg: '#5F27FF',
                    color: 'white',
                  }}>
                  Контакты
                </Button>
              </Link>
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Stack minH={'60vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={5} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={6} w={'full'} maxW={'lg'} align={'center'} textAlign={'center'}>
            <Heading fontSize={{ base: '4xl', md: '3xl', lg: '4xl' }} lineHeight={'1.1'}>
              <Text
                as={'span'}
                position={'relative'}
                color={'#5F27FF'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '20%', md: '30%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: '#FFC400',
                  zIndex: -1,
                }}>
                Арт-терапия
              </Text>
              <br />
              <Text as={'span'} fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}>
                природными материалами
              </Text>
              <br />
              <Text as={'span'} fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}>
                для взрослых и детей
              </Text>
            </Heading>
            <Text fontSize={{ base: 'md', lg: 'lg' }} color={'#1B1B1B'}>
              Это творческий способ рассказать о своих чувствах и мыслях без слов
            </Text>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
              <Button
                rounded={'full'}
                bg={'#5F27FF'}
                color={'white'}
                size={'md'}
                width={'200px'}
                _hover={{
                  bg: 'purple.600',
                }}
                onClick={() => {
                  modal1.onOpen();
                }}>
                Записаться
              </Button>
              <Modal isOpen={modal1.isOpen} size={'sm'} onClose={modal1.onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Запись на арт-терапию</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody align='center' pb='20px'>
                    <Flex flex={3} align={'center'} py={{ base: '3', md: '0' }}>
                      <Stack spacing={4} w={'full'}>
                        <HStack pb={2}>
                          <Box color={'#5F27FF'} px={2}>
                            <Icon as={TbBrandTelegram} w={5} h={5} display={'flex'} />
                          </Box>
                          <VStack align={'start'} textAlign={'left'}>
                            <Text fontWeight={600} fontSize='sm'>Бот для предварительной записи:</Text>
                            <Text fontSize='lg'><a href="https://t.me/Record_art_bot">@record_art_bot</a></Text>
                          </VStack>
                        </HStack>
                        <HStack pb={2}>
                          <Box color={'#5F27FF'} px={2}>
                            <Icon as={TbPhone} w={5} h={5} display={'flex'} />
                          </Box>
                          <VStack align={'start'} textAlign={'left'}>
                            <Text fontWeight={600} fontSize='sm'>Телефон:</Text>
                            <Text fontSize='lg'>8-902-423-92-31</Text>
                          </VStack>
                        </HStack>
                      </Stack>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1} px={{ base: '20', md: '0' }}>
          <Image
            alt={'Login Image'}
            objectFit={'contain'}
            src={cat_for_ta}
          />
        </Flex>
      </Stack>

      <Box py={20}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 2fr)',
          }}
          gap={3}>
          <GridItem colSpan={1} >
            <VStack alignItems="center" spacing="20px">
              <Heading fontSize={{ base: '3xl', md: '3xl' }} fontWeight="700" color="#5F27FF" id="art">
                Что такое арт-терапия?
              </Heading>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 2, sm: 2, md: 2 }}>
            <Flex>
              <Text>
                Арт-терапия («art» - искусство, «therapy» -  терапия, исцеление) — это универсальный и
                адаптируемый подход, который можно применять к широкому спектру эмоциональных и психологических проблем.
              </Text>
            </Flex>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text color={'gray.700'}>Использование цветов и форм помогает лучше понять себя,
                  справиться с эмоциями. </Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text color={'gray.700'}>На сеансах используются природные материалы.
                  Они помогают соединиться с природой и воспользоваться ее успокаивающим действием.</Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text color={'gray.700'}>Это погружение в чувственный опыт, непохожий ни на один другой. </Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text color={'gray.700'}>Здесь важно – ЧТО Вы создаете, а не КАК.</Text>
                </VStack>
              </HStack>
            </GridItem>
            <Flex>
              <Text>
                Это путешествие, где художником быть совсем не обязательно.
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Box>

      <Box py={20}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 2fr)',
          }}
          gap={3}>
          <GridItem colSpan={1}>
            <VStack alignItems="center" spacing="20px">
              <Heading fontSize={{ base: '3xl', md: '3xl' }} fontWeight="700" color="#5F27FF">
                Что происходит на сеансе арт-терапии?
              </Heading>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 2, sm: 2, md: 2 }}>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbPuzzle2} color={'#F67903'} w={8} h={8} />
                </Box>
                <VStack align={'start'}>
                  <Text>Сеанс арт-терапии похож на целенаправленную игру. Речь идет не о создании шедевра –
                    мы говорим только о самовыражении.</Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbMessageCircleQuestion } color={'#FFC400'} w={8} h={8} />
                </Box>
                <VStack align={'start'}>
                  <Text>Когда Вы начнете творить, арт-терапевт может задавать вопросы или просто позволить Вам делать
               свое дело. Искусство становится способом показать, что происходит в Вашей голове и сердце.</Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbBrush } color={'#00D3FF'} w={8} h={8} />
                </Box>
                <VStack align={'start'}>
                  <Text>В конце Вы можете ощутить, что ваши эмоции превратились в цвета и формы. Это немного похоже на
               волшебство: использовать искусство, чтобы лучше понять себя. </Text>
                </VStack>
              </HStack>
            </GridItem>
          </GridItem>
        </Grid>
      </Box>

      <Box p={4}>
        <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
          <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
            Что даёт Арт-терапия?
          </Heading>
        </Stack>

        <Container maxW={'5xl'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            <Cards
              heading={'Эмоциональное освобождение'}
              icon={<Avatar size={'2xl'} src={cat8} />}
              description={'Помогает выразить и обработать свои чувства'}
            />
            <Cards
              heading={'Снижение \nстресса'}
              icon={<Avatar size={'2xl'} src={cat5} />}
              description={'Побуждает к присутствию «здесь» и «сейчас»'}
            />
            <Cards
              heading={'Повышение креативности'}
              icon={<Avatar size={'2xl'} src={cat2} />}
              description={'Стимулирует творческое мышление'}
            />
            <Cards
              heading={'Улучшение навыков решения проблем'}
              icon={<Avatar size={'2xl'} src={cat4} />}
              description={'Помогает творчески решать проблемы'}
            />
            <Cards
              heading={'Связь \nс природой'}
              icon={<Avatar size={'2xl'} src={cat3} />}
              description={'Способствует прямой связи с миром природы'}
            />
          </Flex>
        </Container>
      </Box>

      <Box py={20}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 2fr)',
          }}
          gap={3}>
          <GridItem colSpan={1}>
            <VStack alignItems="center" spacing="20px">
              <Heading fontSize={{ base: '3xl', md: '3xl' }} fontWeight="700" color="#5F27FF" id="about">
                Обо мне
              </Heading>
            </VStack>
            <VStack alignItems="center" spacing="20px">
              <Image
                borderRadius='full'
                boxSize='250px'
                src={ta_4}
              />
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 2, sm: 2, md: 2 }}>
            <Flex>
              <Text>
                Здравствуйте, меня зовут Татьяна Комарова.<br /><br />Я – сертифицированный арт-терапевт и психолог.<br /><br />
                Работаю как со взрослыми, так и с детьми.<br /><br />Провожу арт-терапевтические групповые (открытые и закрытые),
                индивидуальные и семейные (родитель и ребенок) сеансы. Автор индивидуального метафорического променада
                «Встреча с собой».<br /><br />Предпочитаю использовать на сеансах природные материалы.
              </Text>
            </Flex>
            <Heading fontSize="lg" fontWeight={'bold'} color="#1F1F1F" py={5}>
              В сферу моей компетенции входит:
            </Heading>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text fontWeight={600}>Работа с эмоциями и чувствами:</Text>
                  <Text color={'gray.700'}>управление внутренним напряжением, борьба со страхом, тревогой,
                    беспокойством, апатией, агрессией и гневом;</Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text fontWeight={600}>Развитие личности:</Text>
                  <Text color={'gray.700'}>повышение самооценки, облегчение вины, преодоление неуверенности в
                    себе, содействие самопринятию, воспитание независимости;</Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text fontWeight={600}>Улучшение когнитивных процессов:</Text>
                  <Text color={'gray.700'}>повышение уровня самосознания и понимания;</Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text fontWeight={600}>Изменение поведения:</Text>
                  <Text color={'gray.700'}>трансформация неконструктивных стереотипов и принятие более
                    здоровых стратегий поведения и реагирования;</Text>
                </VStack>
              </HStack>
            </GridItem>
            <GridItem py={2}>
              <HStack pb={2}>
                <Box color={'#5F27FF'} px={2}>
                  <Icon as={TbCircleCheck} />
                </Box>
                <VStack align={'start'}>
                  <Text fontWeight={600}>Улучшение коммуникативных навыков:</Text>
                  <Text color={'gray.700'}>решение проблемы закрытости, снижение изоляции и
                    управление обидой;</Text>
                </VStack>
              </HStack>
            </GridItem>
            <Flex>
              <Text>
                Моя цель – с помощью творческого процесса помочь Вам исследовать свою внутреннюю природу, осознать и
                принять себя, восстановить психоэмоциональное равновесие наиболее комфортным способом.<br /><br />
                Я буду рада стать частью вашего творческого приключения.
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Box>

      <Box py={10}>
        <Heading fontSize="3xl" fontWeight="700" color="#5F27FF" textAlign={'center'} pb={10} id="services">
          Описание услуг
        </Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 2fr)',
          }}
          gap={3} py={10}>
          <GridItem colSpan={1}>
            <VStack alignItems="left" spacing="20px">
              <Heading fontWeight="700" color="#5F27FF" fontSize="2xl">
                Групповая арт-терапия<br/>(от 3-х человек)
              </Heading>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 2, sm: 2, md: 2 }}>
            <VStack alignItems="left">
                <Text color="#F67903" fontSize='lg'>
                В этом инновационном подходе мы собираемся вместе, чтобы творить.
                </Text>
                <Text>
                Этот процесс использует успокаивающее влияние природных материалов, способствуя расслаблению и
                хорошему самочувствию.<br /><br />

                Участники не только раскрывают свою творческую сущность, но и обретают чувство принадлежности.
                Групповая динамика способствует разнообразию точек зрения, поощряя обмен идеями и взаимную поддержку,
                что отличает ее от индивидуальной арт-терапии.</Text>
            </VStack>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 2fr)',
          }}
          gap={3} py={10}>
          <GridItem colSpan={1}>
            <VStack alignItems="left" spacing="20px">
              <Heading  fontSize="2xl" fontWeight="700" color="#5F27FF">
                Семейная арт-терапия<br/>
                (ребенок и родитель)
              </Heading>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 2, sm: 2, md: 2 }}>
            <VStack alignItems="left">
                <Text color="#F67903" fontSize='lg'>
                Этот терапевтический подход направлен на укрепление семейных связей.</Text>
                <Text>
                Семейная арт-терапия не только развивает творческие способности, но и создает незабываемые
                воспоминания. Процесс совместного творчества укрепляет эмоциональную связь между членами семьи,
                воспитывает чувство единства и поддержки. По мере того, как дети и родители участвуют в этом
                совместном творческом начинании, они открывают новые способы связи, общения и понимания друг друга.
                </Text>
            </VStack>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 2fr)',
          }}
          gap={3} py={10}>
          <GridItem colSpan={1}>
            <VStack alignItems="left" spacing="20px">
              <Heading  fontSize="2xl" fontWeight="700" color="#5F27FF">
                Индивидуальная<br/>арт-терапия
              </Heading>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 2, sm: 2, md: 2 }}>
            <VStack>
                <Text color="#F67903" fontSize='lg'>
                Индивидуальная арт-терапия представляет собой уникальный путь к самопознанию и исцелению.
                </Text>
                <Text>
                Индивидуализированный характер арт-терапии позволяет глубоко погрузиться в личные переживания и эмоции,
                которые может быть сложно преодолеть в групповой обстановке. Арт-терапевт обеспечивает безопасную
                среду для исследования внутренних мыслей и чувств.<br /><br />
                Благодаря этому процессу можно лучше понять себя, раскрывая слои своей психики.</Text>
            </VStack>
          </GridItem>
        </Grid>

        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            md: 'repeat(3, 2fr)',
          }}
          gap={3} py={10}>
          <GridItem colSpan={1}>
            <VStack alignItems="left" spacing="20px">
              <Heading  fontSize="2xl" fontWeight="700" color="#5F27FF">
                Индивидуальный<br/>метафорический променад<br/>«Встреча с собой»
              </Heading>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 2, sm: 2, md: 2 }}>
            <VStack>

                <Text color="#F67903" fontSize='lg'>
                Этот захватывающий опыт позволяет метафорически исследовать свой внутренний мир,
                используя природные
                объекты как символические представления эмоций и мыслей.</Text>
                <Text>
                Индивидуальный метафорический променад предоставляет уникальную возможность для самопознания.
                Природная среда служит зеркалом внутреннего мира, предлагая идеи, которые, возможно, не будут
                обнаружены в традиционных условиях.<br /><br />
                Метафорический язык природы позволяет глубже изучить эмоции, образуя чувство связи как с внешней
                средой, так и с внутренним ландшафтом.</Text>

            </VStack>
          </GridItem>
        </Grid>
      </Box>

      <Box width='100%'>
        <Box py={6} px={5} width="full">
          <Stack spacing={4} width={'100%'} direction={'column'}>
            <Stack
              p={5}
              alignItems={'center'}
              justifyContent={{
                base: 'flex-start',
                md: 'space-around',
              }}
              direction={{
                base: 'column',
                md: 'row',
              }}>
              <Stack
                width={{
                  base: '100%',
                  md: '40%',
                }}
                textAlign={'center'}>
                <Heading size={'lg'} id="prices">
                  Стоимость <Text color="#5F27FF">занятий</Text>
                </Heading>
              </Stack>
              <Stack
                width={{
                  base: '100%',
                  md: '60%',
                }}>
                <Text textAlign={'center'}>
                  Арт-терапия - это процесс, который позволит Вам распутать клубок эмоций и мыслей с помощью языка искусства
                </Text>
              </Stack>
            </Stack>
            <Divider />
            <PackageTier title={'Групповая арт-терапия*'} info={'По предварительной записи'} />
            <Text textAlign={'left'} fontSize='xs' px={3}>
              *сеанс групповой арт-терапии проводится при формировании группы от 3-х человек
            </Text>
            <Divider />
            <PackageTier title={'Семейная арт-терапия'} info={'По предварительной записи'} />
            <Divider />
            <PackageTier title={'Индивидуальная арт-терапия'} info={'По предварительной записи'} />
            <Divider />
            <PackageTier title={'Индивидуальный \u000Aметафорический променад'} info={'По предварительной записи'} />
          </Stack>
        </Box>
      </Box>

      <Heading fontSize="3xl" fontWeight="700" color="#5F27FF" textAlign={'center'} py={10} id="services">
        Образование
      </Heading>
      <Box position={'relative'} height={{ base: '300px', md: '500px' }} width={'68%'} left={'50%'} transform={'translateX(-50%)'}>
        {/* CSS files for react-slick */}
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        {/* Left Icon */}
        <IconButton
          aria-label="left-arrow"
          bg={'#5F27FF'}
          color={'#F7FAFF'}
          borderRadius="full"
          position="absolute"
          left={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}>
          <ChevronLeftIcon />
        </IconButton>
        {/* Right Icon */}
        <IconButton
          aria-label="right-arrow"
          bg={'#5F27FF'}
          color={'#F7FAFF'}
          borderRadius="full"
          position="absolute"
          right={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}>
          <ChevronRightIcon />
        </IconButton>
        {/* Slider */}
        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          {cards.map((url, index) => (
            <Box
              key={index}
              height={{ base: '280px', md: '480px' }}
              position="relative"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundImage={`url(${url})`}
            />
          ))}
        </Slider>
      </Box>

      <Stack minH={'60vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex flex={2} px={{ base: '20', md: '0' }} py={{ base: '10', md: '0' }}>
          <Image
            objectFit={'contain'}
            src={cat7}
          />
        </Flex>
        <Flex flex={3} align={'center'} py={{ base: '7', md: '0' }}>
          <Stack spacing={4} w={'full'}>
            <Heading id="contacts" pb={{ base: '5', md: '2' }}>Контакты</Heading>
            <HStack pb={2}>
              <Box color={'#5F27FF'} px={2}>
                <Icon as={TbBrandTelegram} w={5} h={5} display={'flex'} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600} fontSize='sm'>Бот для предварительной записи:</Text>
                <Text fontSize='lg'><a href="https://t.me/Record_art_bot">@record_art_bot</a></Text>
              </VStack>
            </HStack>
            <HStack pb={2}>
              <Box color={'#5F27FF'} px={2}>
                <Icon as={TbPhone} w={5} h={5} display={'flex'} />
              </Box>
              <VStack align={'start'}>
                <Text fontSize='lg'>8-902-423-92-31</Text>
              </VStack>
            </HStack>
            <HStack pb={2}>
              <Box color={'#5F27FF'} px={2}>
                <Icon as={TbMail} w={5} h={5} display={'flex'} />
              </Box>
              <VStack align={'start'}>
                <Text fontSize='lg'>takomarovaprof@gmail.com</Text>
              </VStack>
            </HStack>
            <HStack pb={2}>
              <Box color={'#5F27FF'} px={2}>
                <Icon as={TbMapPin} w={5} h={5} display={'flex'} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600} fontSize='sm'>Место проведения групповой, семейной и индивидуальной арт-терапии:</Text>
                <Text fontSize='lg'>г. Зеленоградск, ул. Ленина 32а «Hotel 12»</Text>
              </VStack>
            </HStack>
            <HStack pb={2}>
              <Box color={'#5F27FF'} px={2}>
                <Icon as={TbMapPin} w={5} h={5} display={'flex'} />
              </Box>
              <VStack align={'start'}>
                <Text fontWeight={600} fontSize='sm'>Место проведения индивидуального променада «Встреча с собой»:</Text>
                <Text fontSize='lg'>г. Зеленоградск, Городской парк</Text>
              </VStack>
            </HStack>
          </Stack>
        </Flex>
      </Stack>

      <Box >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          spacing={4}
          justify={'center'}
          align={'center'}
          display={{base: 'none', md: 'flex' }}>
          <Stack direction={'row'} spacing={6}>
            <Box as="a" href={'#art'}>
              Арт-терапия
            </Box>
            <Box as="a" href={'#about'}>
              Обо мне
            </Box>
            <Box as="a" href={'#services'}>
              Услуги
            </Box>
            <Box as="a" href={'#prices'}>
              Цены
            </Box>
            <Box as="a" href={'#contacts'}>
              Контакты
            </Box>
          </Stack>
        </Container>

        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('#5F27FF', '#5F27FF')}>
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}>
            <Text><a href="https://ru.freepik.com/free-vector/flat-cute-ginger-cats-in-different-
                funny-poses-playing-and-relaxing_27059393.htm">
                Изображения от redgreystock</a> на Freepik</Text>
          </Container>
        </Box>
      </Box>
    </Container>
  );
};

export default Site;